<template>
    <div
      class="page-header align-items-start min-vh-100"
      style="
        background-image: url('https://cdn.pixabay.com/photo/2017/10/26/17/51/under-construction-2891888_960_720.jpg 1x, https://cdn.pixabay.com/photo/2017/10/26/17/51/under-construction-2891888_1280.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-auto">
        <div class="row">m 
            <h4 style="color: orange !important;">MODERN SOFTWARE TRENDS SITE</h4>
            <h1 style="color: yellow !important;">IS CURRENTLY UNDER RECONSTRUCTION</h1>
            <hr>
            <h5 style="color: white !important;">We will be back soon...Thanks a lot for your patience and understanding  <i class="fa fa-heart"></i></h5>
            <br>
            <h6 style="color: aqua;">Until then you can access our contents on our ==> <u><a href="https://modernsoftwaretrends.blogspot.com/" style="color: aqua;font-size: large;"> MODERN SOFTWARE TRENDS BLOG</a></u> </h6>
            <br>
            <br>
            <br>
            <p style="color: lightgray;"> <i>We are changing the tech stack and UI\UX is being completely redesigned (so it might take some time)</i></p>
        </div>
        
      </div>
    
    </div>
  </template>
  
  <script>
  import { mapMutations } from "vuex";
  
  export default {
    name: "site-under-reconstruction",
    components: {
    },
    beforeMount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    beforeUnmount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
  };
  </script>
  